<template>
  <div>
    <el-tabs v-model="activeTabs" @tab-click="handleClick">
      <el-tab-pane label="司机端小程序配置" name="1" />
      <el-tab-pane label="调度端小程序配置" name="5" />
      <el-tab-pane label="客户端小程序配置" name="6" />
      <el-tab-pane label="公众号配置" name="3" />
      <el-tab-pane label="支付配置" name="4" />
      <el-tab-pane label="文件上传配置" name="2" />
      <!--<el-tab-pane label="易流云配置" name="7" />-->
      <el-tab-pane label="阿里云语音服务工具" name="8" />
      <el-tab-pane label="高德地图支持工具" name="9" />
      <el-tab-pane label="公众号发送模版" name="10" />
      <el-tab-pane label="腾讯地图支持工具" name="11" />
      <el-tab-pane label="消息通知配置" name="12" />
      <el-tab-pane label="事项代办超时告警配置" name="13" />
    </el-tabs>
    <el-form ref="form" :model="formData" label-width="200px" style="width: 60%;" size="small" v-loading="formLoading">
      <template v-if="['1', '5', '6'].includes(activeTabs)">
        <el-form-item label="示例:">
          <el-link type="info">登录微信公众平台: https://mp.weixin.qq.com/</el-link>
        </el-form-item>
        <el-form-item label="小程序APPID:">
          <el-input v-model="formData.miniProgram.appId"></el-input>
        </el-form-item>
        <el-form-item label="小程序Secret:">
          <el-input v-model="formData.miniProgram.secret"></el-input>
        </el-form-item>
        <el-form-item label="消息服务Token">
          <el-input v-model="formData.miniProgram.token"></el-input>
        </el-form-item>
        <el-form-item label="EncodingAESKey">
          <el-input v-model="formData.miniProgram.aesKey"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '3'">
        <el-form-item label="示例:">
          <el-link type="info">登录微信公众平台: https://mp.weixin.qq.com/</el-link>
        </el-form-item>
        <el-form-item label="公众号APPID:">
          <el-input v-model="formData.publicAccount.appId"></el-input>
        </el-form-item>
        <el-form-item label="公众号Secret:">
          <el-input v-model="formData.publicAccount.appSecret"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '4'">
        <el-form-item label="示例:">
          <el-link type="info">登录微信商户平台: https://pay.weixin.qq.com/index.php/apply/applyment_home/guide_normal</el-link>
        </el-form-item>
        <el-form-item label="商户号:">
          <el-input v-model="formData.wxPay.mchId"></el-input>
        </el-form-item>
        <el-form-item label="商户密钥:">
          <el-input v-model="formData.wxPay.mchKey"></el-input>
        </el-form-item>
        <el-form-item label="证书地址:">
          <el-input v-model="formData.wxPay.keyPath"></el-input>
        </el-form-item>
        <el-form-item label="司机端证书地址:">
          <el-input v-model="formData.wxPay.driverKeyPath"></el-input>
        </el-form-item>
        <el-form-item label="回调地址:">
          <el-input v-model="formData.wxPay.notifyUrl"></el-input>
        </el-form-item>
        <el-form-item label="V3支付路径:">
          <el-input v-model="formData.wxPay.v3Payment"></el-input>
        </el-form-item>
        <el-form-item label="公钥序列号:">
          <el-input v-model="formData.wxPay.wechatPaySerial"></el-input>
        </el-form-item>
        <el-form-item label="私钥文件路径:">
          <el-input v-model="formData.wxPay.v3PrivateKeyPath"></el-input>
        </el-form-item>
        <el-form-item label="公钥证书路径:">
          <el-input v-model="formData.wxPay.v3PlatformKeyPath"></el-input>
        </el-form-item>
        <el-form-item label="证书序列号:">
          <el-input v-model="formData.wxPay.v3SerialNo"></el-input>
        </el-form-item>
        <el-form-item label="调用地址:">
          <el-input v-model="formData.wxPay.localIP"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '2'">
        <el-form-item label="示例:">
          <el-link type="info">登录阿里云平台: https://www.aliyun.com/product/oss?</el-link>
        </el-form-item>
        <el-form-item label="空间域名 Domain:">
          <el-input v-model="formData.sysFileConfig.spaceDomain"></el-input>
        </el-form-item>
        <el-form-item label="存储类型:">
          <el-select v-model="formData.sysFileConfig.type" placeholder="请选择活动区域">
            <el-option label="本地存储" :value="0" :disabled="true"></el-option>
            <el-option label="阿里云OSS" :value="1"></el-option>
            <el-option label="亚马逊S3" :value="2" :disabled="true"></el-option>
            <el-option label="七牛云" :value="3" :disabled="true"></el-option>
            <el-option label="Google Cloud Storage" :value="4" :disabled="true"></el-option>
            <el-option label="Azure Blob Storage" :value="5" :disabled="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Bucket域名:">
          <el-input v-model="formData.sysFileConfig.endpoint"></el-input>
        </el-form-item>
        <el-form-item label="accessKeyId:">
          <el-input v-model="formData.sysFileConfig.accessKeyId"></el-input>
        </el-form-item>
        <el-form-item label="accessKeySecret:">
          <el-input v-model="formData.sysFileConfig.accessKeySecret"></el-input>
        </el-form-item>
        <el-form-item label="存储空间名称(Bucket):">
          <el-input v-model="formData.sysFileConfig.bucket"></el-input>
        </el-form-item>
        <el-form-item label="存储路径:">
          <el-input v-model="formData.sysFileConfig.path">
            <template slot="prepend">{{ tenantPrepend }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="允许上传文件后缀:">
          <el-input v-model="formData.sysFileConfig.allowedFiles"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '8'">
        <el-form-item label="公钥:">
          <el-input v-model="formData.aiLiVoiceConfig.publicKey"></el-input>
        </el-form-item>
        <el-form-item label="私钥:">
          <el-input v-model="formData.aiLiVoiceConfig.privateKey"></el-input>
        </el-form-item>
        <el-form-item label="订单通知语音模板:">
          <el-input v-model="formData.aiLiVoiceConfig.orderNoticeTemplate"></el-input>
        </el-form-item>
        <el-form-item label="订单报关语音模板:">
          <el-input v-model="formData.aiLiVoiceConfig.orderCustomsTemplate"></el-input>
        </el-form-item>
        <el-form-item label="主叫号码:">
          <el-input v-model="formData.aiLiVoiceConfig.callerNumber"></el-input>
        </el-form-item>
        <el-form-item label="语音通知产品ID:">
          <el-input v-model="formData.aiLiVoiceConfig.productId" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="延时（毫秒）:">
          <el-input v-model="formData.aiLiVoiceConfig.delayMilliSecond" type="Number"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '9'">
        <el-form-item label="高德地图Key:">
          <el-input v-model="formData.gaoDeMap.key"></el-input>
        </el-form-item>
        <el-form-item label="经纬度地址:">
          <el-input v-model="formData.gaoDeMap.getLocation"></el-input>
          <el-link type="info">示例: https://restapi.amap.com/v3/geocode/geo</el-link>
        </el-form-item>
        <el-form-item label="获取位置间距:">
          <el-input v-model="formData.gaoDeMap.getDistance"></el-input>
          <el-link type="info">示例: https://restapi.amap.com/v3/distance</el-link>
        </el-form-item>
        <el-form-item label="搜索POI:">
          <el-input v-model="formData.gaoDeMap.searchPOI"></el-input>
          <el-link type="info">示例: https://restapi.amap.com/v5/place/text</el-link>
        </el-form-item>
        <el-form-item label="坐标转换:">
          <el-input v-model="formData.gaoDeMap.coordinateTransformation"></el-input>
          <el-link type="info">示例: https://restapi.amap.com/v3/assistant/coordinate/convert</el-link>
        </el-form-item>
        <el-form-item label="经纬度查城市:">
          <el-input v-model="formData.gaoDeMap.queryTheCityNameByLatitudeAndLongitude"></el-input>
          <el-link type="info">示例: https://restapi.amap.com/v3/geocode/regeo</el-link>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '10'">
        <el-form-item label="货款支付成功通知:">
          <el-input v-model="formData.pubTemplate.paymentSuccessMsg"></el-input>
        </el-form-item>
        <el-form-item label="新运单配送通知:">
          <el-input v-model="formData.pubTemplate.newOrderDeliveryMsg"></el-input>
        </el-form-item>
        <el-form-item label="付款方:">
          <el-input v-model="formData.pubTemplate.payer"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '11'">
        <el-form-item label="腾讯地图Key:">
          <el-input v-model="formData.tencentMap.key"></el-input>
        </el-form-item>
        <el-form-item label="键词输入提示:">
          <el-input v-model="formData.tencentMap.suggestionUrl"></el-input>
          <el-link type="info">示例: https://apis.map.qq.com/ws/place/v1/suggestion</el-link>
        </el-form-item>
        <el-form-item label="地址纠正补全API:">
          <el-input v-model="formData.tencentMap.addressComplete"></el-input>
          <el-link type="info">示例: https://apis.map.qq.com/ws/smart_address/address_complete</el-link>
        </el-form-item>
        <el-form-item label="逆地址解析:">
          <el-input v-model="formData.tencentMap.geocoderUrl"></el-input>
          <el-link type="info">示例: https://apis.map.qq.com/ws/geocoder/v1/</el-link>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '12'">
        <el-form-item label="accessKey:">
          <el-input v-model="formData.sendMessageConfig.accessKeyId"></el-input>
        </el-form-item>
        <el-form-item label="accessKeySecret:">
          <el-input v-model="formData.sendMessageConfig.accessKeySecret"></el-input>
        </el-form-item>
        <el-form-item label="签名模版:">
          <el-input v-model="formData.sendMessageConfig.signName"></el-input>
        </el-form-item>
        <el-form-item label="注册验证模版:">
          <el-input v-model="formData.sendMessageConfig.templateDefaultRegister"></el-input>
        </el-form-item>
        <el-form-item label="登录验证模版:">
          <el-input v-model="formData.sendMessageConfig.templateDefaultLogin"></el-input>
        </el-form-item>
        <el-form-item label="注册审核通过通知模板:">
          <el-input v-model="formData.sendMessageConfig.templateDefaultNotice"></el-input>
        </el-form-item>
        <el-form-item label="认证审核通过短信通知模板:">
          <el-input v-model="formData.sendMessageConfig.templateDefaultCustomer"></el-input>
        </el-form-item>
        <el-form-item label="车次发布通知模板:">
          <el-input v-model="formData.sendMessageConfig.templatePlanPublishNotice"></el-input>
        </el-form-item>
        <el-form-item label="车次取消通知模板:">
          <el-input v-model="formData.sendMessageConfig.templatePlanCancelNotice"></el-input>
        </el-form-item>
        <el-form-item label="发放工资通知模板:">
          <el-input v-model="formData.sendMessageConfig.templateWageSendNotice"></el-input>
        </el-form-item>
        <el-form-item label="报关短信通知模板:">
          <el-input v-model="formData.sendMessageConfig.templateEntranceSendNotice"></el-input>
        </el-form-item>
      </template>
      <template v-if="activeTabs === '13'">
        <el-form-item label="订单列表-订单审核">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.statusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单管理-安排车辆">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.operateModeOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单运输流程-司机操作APP">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.confirmStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="异常管理-异常状态">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.errorStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单列表-回单核对">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.receiptStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单费用核对-客服对账">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.careStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单费用核对-主管对账">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.deptStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单费用核对-财务对账">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.costStatusOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">分</template></el-input>
        </el-form-item>
        <el-form-item label="订单收款核对">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.writeStatusCollectionOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">天</template></el-input>
        </el-form-item>
        <el-form-item label="订单付款核对">
          <el-input type="number" v-model="formData.configureTheTimeoutAlarmConfig.writeStatusPaymentOvertime" autocomplete="off" size="small"
                    max="9999999" min="1"
                    onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                    onchange="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
                    oninput="if(value){value=value.replace(/[^\d]/g,1);if(Number(value)<=1){value=1}} if(Number(value)>9999999){value=9999999}"
          ><template slot="append">天</template></el-input>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button type="primary" size="mini" @click="submit" :loading="btnLoading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTabs: '1',
      id: null,
      formArr: ['miniProgram', 'sysFileConfig', 'publicAccount', 'wxPay', 'miniProgram', 'miniProgram', 'ylyConfig','aiLiVoiceConfig', 'gaoDeMap', 'pubTemplate', 'tencentMap', 'sendMessageConfig','configureTheTimeoutAlarmConfig'],
      formData: {
        miniProgram: {
          msgDataFormat: 'JSON'
        },
        sysFileConfig: {},
        publicAccount: {},
        wxPay: {},
        //ylyConfig: {},
        aiLiVoiceConfig: {},
        gaoDeMap: {},
        pubTemplate: {},
        tencentMap: {},
        sendMessageConfig: {},
        configureTheTimeoutAlarmConfig: {},
      },
      btnLoading: false,
      formLoading: false,
      tenantPrepend: sessionStorage.getItem('Tenant-Id') || 10000
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      let that = this
      that.formLoading = true
      that.$http({
        url: that.$http.adornUrl('/sys/appConfig/get'),
        method: 'get',
        params: that.$http.adornParams({ type: that.activeTabs })
      }).then(({ data }) => {
        that.id = data.id
        that.formData[that.formArr[Number(that.activeTabs) - 1]] = data.configValue ? JSON.parse(data.configValue) : {}
        setTimeout(() => {
          that.formLoading = false
        }, 500)
        that.$forceUpdate()
      }).catch(() => {
        setTimeout(() => {
          that.formLoading = false
        }, 500)
      })
    },
    handleClick(tab, event) {
      this.getDetails()
    },
    submit() {
      let that = this
      that.btnLoading = true
      let params = {}
      params[that.formArr[that.activeTabs - 1]] = that.formData[that.formArr[Number(that.activeTabs) - 1]]
      if (that.id) params.id = that.id
      params.configType = Number(that.activeTabs)
      that.$http({
        url: that.$http.adornUrl(that.id ? '/sys/appConfig/update' : '/sys/appConfig/add'),
        method: 'post',
        data: that.$http.adornParams(params)
      }).then(({ data }) => {
        that.$message.success('操作成功！')
        this.getDetails()
        setTimeout(() => {
          that.btnLoading = false
        }, 500)
      }).catch(() => {
        setTimeout(() => {
          that.btnLoading = false
        }, 500)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
